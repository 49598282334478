import React from "react";
import { Typography, Divider, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export function Header () {
    return (
        <React.Fragment>
            <Typography variant='h4'><RouterLink to={'/'}>青空文庫.split</RouterLink></Typography>
            <Divider variant='middle' />
        </React.Fragment>
    );
};

export function Footer () {
    return (
        <React.Fragment>
            <Divider variant='middle' />
            <Typography variant='body1'>
                <Link href="https://docs.google.com/forms/d/e/1FAIpQLSeSTa4bhIKoLixDe17bOyM_I6cj6uUzKasFalrb8-U72eWc8Q/viewform">お問合せ</Link><br />
                <RouterLink to={'/policy'}>プライバシーポリシー</RouterLink><br />
                © 2023-2024 Shinra
            </Typography>
        </React.Fragment>
    )
}