import React from 'react';
import { Box, createTheme, responsiveFontSizes, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { Footer, Header } from './components/CommonParts';
import { Routes, Route } from 'react-router-dom';
import { Classic } from './page/Classic';
import { Top } from './page/Top';
import { BookList } from './page/BookList';
import { PersonList } from './page/PersonList';
import { SearchList } from './page/SearchList';
import { TextReader } from './page/TextReader';
import { BookReader } from './page/BookReader';
import { NotFound } from './page/NotFound';
import { Usage } from './page/Usage';
import { Policy } from './page/Policy';

function App() {

  let theme = createTheme({
    palette: {
      background: {
        default: grey[200],
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        md: 750,
        lg: 1100,
      }
    },
    typography: {
      h4: {
        fontWeight: 'bold',
      },
      h5: {
        fontWeight: 'bold',
      },
      h6: {
        fontWeight: 'bold',
      },
      subtitle1: {
        fontWeight: 'bold',
      },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{
        bgcolor: 'background.default',
        pt: 2,
        px: '5%',
      }}>
        <Header />
        <Routes>
          <Route path='/' element={ <Top /> } />
          <Route path='/classic' element={ <Classic /> } />
          <Route path='/booklist' element={ <BookList /> } />
          <Route path='/personlist' element={ <PersonList /> } />
          <Route path='/searchlist' element={ <SearchList /> } />
          <Route path='/textreader' element={ <TextReader /> } />
          <Route path='/bookreader' element={ <BookReader /> } />
          <Route path='/usage' element={ <Usage /> } />
          <Route path='/policy' element={ <Policy /> } />
          <Route path='*' element={ <NotFound /> } />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
