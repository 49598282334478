import { Link, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import { Button, Container, List, ListItem, Paper, Typography } from "@mui/material";
import { ListItemBox } from "./MyBox";
import { DefaultButton, LinkButton } from "./MyButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';

const containKatakana = (str) => {
    const katakanaRegex = /^[\u30A1-\u30F6ァ-ヶー]+$/;
    return katakanaRegex.test(str);
};

export function PagingLinks({
    path = '',
    offset = 0,
    links = {
        prev: null,
        next: null,
    },
    length = 0,
    index = 0,
    pos = 'head',
    keyword,
    mode,
}) {
    // 前へ、現在位置前後4ページずつ、次へ
    const paging = [];
    if (links.prev !== null) {
        let prevTo = '';
        if (keyword === null) {
            prevTo = `${path}?index=${index}&offset=${links.prev?.offset}`;            
        } else {
            prevTo = `${path}?keyword=${keyword}&mode=${mode}&offset=${links.prev?.offset}`;
        }
        const pKey = `${pos}_prev`;
        paging.push(<Link key={pKey} to={prevTo}>前へ</Link>);
        paging.push(parse(' '));
    } else {
        paging.push(parse('前へ '));
    }

    const currentPos = (offset / 50) + 1;
    const maxPos = (length / 50) + 1;
    let startPos = 1;
    let endPos = maxPos;
    if (maxPos > 9) {
        if (currentPos > 5) startPos = currentPos - 4;
        if (startPos + 8 < maxPos) endPos = startPos + 8;
    }
    for (let i = startPos; i <= endPos; i++) {
        if (i === currentPos) {
            paging.push(parse(`${i} `));
        } else {
            let to = '';
            if (keyword === null) {
                to = `${path}?index=${index}&offset=${50 * (i - 1)}`;
            } else {
                to = `${path}?keyword=${keyword}&mode=${mode}&offset=${50 * (i - 1)}`;
            }
            const iKey = `${pos}_${i}`;
            paging.push(<Link key={iKey} to={to}>{i}</Link>);
            paging.push(parse(' '));
        }
    }

    if (links.next !== null) {
        let nextTo = '';
        if (keyword === null) {
            nextTo = `${path}?index=${index}&offset=${links.next?.offset}`;
        } else {
            nextTo = `${path}?keyword=${keyword}&mode=${mode}&offset=${links.next?.offset}`;
        }
        const nKey = `${pos}_next`
        paging.push(<Link key={nKey} to={nextTo}>次へ</Link>);
    } else {
        paging.push(parse('次へ '));
    }
    return (<>{paging}</>);
}

export function ResultTable({
    results = [],
    isBook = true,
}) {
    const regexPattern = /^https:\/\/www\.aozora\.gr\.jp\/cards\/\d+\/files\/\d+_\d+\.html$/;
    const navigate = useNavigate();

    let list = [];

    if (isBook) {
        list = results.map((book) => {
            const fullname = (containKatakana(book.firstname) && containKatakana(book.lastname)) ? `${book.firstname} ${book.lastname}` : `${book.lastname} ${book.firstname}`;
            
            const readableFlag = regexPattern.test(book.html_url);

            const urlSplit = readableFlag ? book.html_url.split('/') : [];
            const cardNum = readableFlag ? urlSplit[4] : '';
            const fileNum = readableFlag ? urlSplit[6].split('.')[0] : '';

            return (
                <ListItem
                    key={book._id}
                    disablePadding
                    sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '50%',
                        },
                        maxWidth: {
                            xs: '100%',
                            md: '50%',
                        },
                    }}
                >
                    <ListItemBox>
                        <Typography noWrap variant='h6'>{book.title}</Typography>
                        <Typography noWrap variant='subtitle1'>{fullname}</Typography>
                        <LinkButton
                            href={book.card_url}
                            endIcon={<OpenInNewIcon />}
                        >
                            <Typography variant='button'>詳細</Typography>
                        </LinkButton>
                        <DefaultButton
                            onClick={() => {if (readableFlag) navigate(`/bookreader?cardNum=${cardNum}&fileNum=${fileNum}`)}}
                            endIcon={readableFlag ? <FileDownloadIcon /> : <FileDownloadOffIcon />}
                        >
                            <Typography variant='button'>{readableFlag ? '読む' : '非対応'}</Typography>
                        </DefaultButton>
                    </ListItemBox>
                </ListItem>
            );
        });
    } else {
        list = results.map((person) => {
            const kanaFlag = (containKatakana(person.firstname) && containKatakana(person.lastname));
            const fullname = kanaFlag ? `${person.firstname} ${person.lastname}` : `${person.lastname} ${person.firstname}`;
            const fullname_yomi = kanaFlag ? `${person.firstname_yomi} ${person.lastname_yomi}` : `${person.lastname_yomi} ${person.firstname_yomi}`;
            return (
                <ListItem
                    disablePadding
                    sx={{
                        flexBasis: {
                            xs: '100%',
                            md: '50%',
                        },
                        maxWidth: {
                            xs: '100%',
                            md: '50%',
                        },
                    }}
                >
                    <ListItemBox>
                        <Typography noWrap variant='h6'>{fullname}</Typography>
                        <Typography noWrap variant='subtitle1'>{fullname_yomi}</Typography>
                        <Button variant='contained'
                            onClick={() => navigate(`/searchlist?keyword=${encodeURI(fullname)}&mode=3`)}>
                            著作検索
                        </Button>
                    </ListItemBox>
                </ListItem>
            );
        });
    }

    return (<Container component={Paper}><List sx={{ display: 'flex', flexWrap: 'wrap' }}>{list}</List></Container>);

}