import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PagingLinks, ResultTable } from "../components/ListParts";
import { SearchBox } from "../components/InputParts";

export function SearchList() {
    //検索キーワード
    const [searchKeyword, setKeyword] = useState('');
    //検索モード
    const [searchMode, setSearchMode] = useState(1);

    const handleKeyword = (event) => {
        setKeyword(event.target.value);
    }
    const handleSearchMode = (event) => {
        setSearchMode(parseInt(event.target.value));
    }


    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const keyword = query.has('keyword') ? decodeURI(query.get('keyword')) : '';
    const mode = query.has('mode') ? parseInt(query.get('mode')) : 1;
    const offset = query.has('offset') ? parseInt(query.get('offset')) : 0;

    const [list, setList] = useState([]);
    const [number, setNumber] = useState(0);
    const [links, setLinks] = useState({});

    //検索用関数(キーワード、モード(作品名、著者、著作))
    const searchData = async (word = '',mode = 1, offset = 0) => {
        return await fetch(`/search?keyword=${word}&mode=${mode}&offset=${offset}`)
            .then((res) => res.json());
    };

    useEffect(() => {
        searchData(keyword,mode,offset)
            .then((json) => {
                setList(json.result);
                setNumber(json.length);
                setLinks(json.links);
            });
        setKeyword(keyword);
        setSearchMode(mode);
    },[keyword, mode, offset]);

    return (
        <Box
            sx={{
                mx: 'auto',
            }}
        >
            <SearchBox
                keyword={searchKeyword}
                searchMode={searchMode}
                handleKeyword={handleKeyword}
                handleSearchMode={handleSearchMode}
            />
            <Divider variant='middle' />
            <Typography noWrap variant='h5'>「{keyword}」を含む{mode === 1 ? '小説' : '著者'}</Typography>
            <Typography noWrap variant='h6'>{number}件</Typography>
            <Divider variant='middle' />
            <PagingLinks
                path={'/searchlist'}
                offset={offset}
                links={links}
                length={number}
                keyword={keyword}
                mode={mode}
            />
            <Divider variant='middle' />
            <ResultTable
                results={list}
                isBook={!(mode === 2)}
            />
            <PagingLinks
                path={'/searchlist'}
                offset={offset}
                links={links}
                length={number}
                pos={'foot'}
                keyword={keyword}
                mode={mode}
            />
            <Divider variant='middle' />
        </Box>
    );
}