import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Const from "../Const";
import { PagingLinks, ResultTable } from "../components/ListParts";

export function PersonList() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const index = query.has('index') ? parseInt(query.get('index')) : 0;
    const offset = query.has('offset') ? parseInt(query.get('offset')) : 0;

    const [list, setList] = useState([]);
    const [number, setNumber] = useState(0);
    const [links, setLinks] = useState({});
    const initial = Const.INITIALS[index];

    //検索用関数(キーワード、モード(作品名、著者、著作))
    const searchInitial = async (word = '', offset = 0) => {
        return await fetch(`/initialsearch?initial=${word}&mode=2&offset=${offset}`)
            .then((res) => res.json());
    };

    useEffect(() => {
        searchInitial(initial,offset)
            .then((json) => {
                setList(json.result);
                setNumber(json.length);
                setLinks(json.links);
            });
    },[initial, offset]);

    return (
        <Box
            sx={{
                mx: 'auto',
            }}
        >
            <Typography noWrap variant='h5'>「{initial}」から始まる著者</Typography>
            <Typography noWrap variant='h6'>{number}件</Typography>
            <Divider variant='middle' />
            <PagingLinks
                path={'/personlist'}
                offset={offset}
                links={links}
                length={number}
                index={index}
            />
            <Divider variant='middle' />
            <ResultTable
                results={list}
                isBook={false}
            />
            <PagingLinks
                path={'/personlist'}
                offset={offset}
                links={links}
                length={number}
                index={index}
                pos={'foot'}
            />
            <Divider variant='middle' />
        </Box>
    );
}