import React from "react";
import { Component } from "react";
import postscribe from "postscribe";

class ScriptTag extends Component {
    componentDidMount() {
       postscribe(
        '#admax',
        '<script src="https://adm.shinobi.jp/s/1f03ab31438936db9da5ab4165a909a2"></script>',
       );
    }

    render() {
        return <div id='admax' />
    }
}

export default ScriptTag
