import React, { useEffect, useState } from "react";
import NovelViewer from "../components/NovelViewer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Divider } from "@mui/material";
import { OriginalInputter } from "../components/InputParts";

export function TextReader() {
    const isClassic = false;
    const isAozora = false;

    const { state } = useLocation();

    const postTextData = async () => {
        const title = await  state?.title;
        const text = await  state?.text;
        // console.log(title);
        // console.log(text);
                        
        axios.post('/textanalyze', {
            title: title,
            text: text,
        })
            .then((result) => result.data)
            .then((data) => {
                setNovel({
                ...novel,
                author: data.author,
                title: data.title,
                mainText: data.mainText,
                });
            });
    }

    //小説情報
    const [novel, setNovel] = useState({
        //著者
        author: '',
        //題名
        title: 'テキスト読み込み中',
        //本文（章単位で章題と文節で区切った本文のペア）
        mainText: [['本文', ['テキスト読込前']]],
    });

    //現在の再生位置
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tmpIntCIValue, setTmpIntCIValue] = useState(0);
    //再生中か否か
    const [isPlaying, setIsPlaying] = useState(false);
    //再生中の章
    const [contentIndex, setContentIndex] = useState(0);

    useEffect(() => {
        postTextData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePost = () => {
        postTextData();
    } 

    return (
        <React.Fragment>
            <OriginalInputter
                handlePost={handlePost}
            />
            <Divider variant='middle' />
            <NovelViewer
                isPlaying={isPlaying}
                novel={novel}
                novelUrl={''}
                contentIndex={contentIndex}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setContentIndex={setContentIndex}
                setIsPlaying={setIsPlaying}
                tmpIntCIValue={tmpIntCIValue}
                setTmpIntCIValue={setTmpIntCIValue}
                isClassic={isClassic}
                isAozora={isAozora}
            />
        </React.Fragment>
    );
}