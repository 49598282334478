import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultBox, FlexBox, FlexHandlerBox, FlexMdBox, HalfBox } from "./MyBox";
import { DefaultField, MultilineField, TitleField } from "./MyTextField";
import { DefaultButton } from "./MyButton";
import SearchIcon from '@mui/icons-material/Search';
import { FormControlLabel, Radio, Typography } from "@mui/material";

export function SearchBox({
    keyword,
    searchMode,
    handleKeyword,
    handleSearchMode,
}) {
    const navigate = useNavigate();
    return (
        <FlexMdBox>
            <FlexHandlerBox>
                <DefaultField
                    value={keyword}
                    onChange={(event) => handleKeyword(event)}
                    label='キーワード'
                />
                <DefaultButton
                    onClick={() => {
                        if (keyword === '') return;
                        navigate(`/searchlist?keyword=${encodeURI(keyword)}&mode=${searchMode}`);
                    }}
                    endIcon={<SearchIcon />}
                >
                    <Typography variant='button'>検索</Typography>
                </DefaultButton>
            </FlexHandlerBox>
            <FlexBox>
                <FormControlLabel
                    value='1'
                    control={
                        <Radio
                            checked={searchMode === 1}
                            onChange={handleSearchMode}
                        />
                    }
                    label='書名'
                />
                <FormControlLabel
                    value='2'
                    control={
                        <Radio
                            checked={searchMode === 2}
                            onChange={handleSearchMode}
                        />
                    }
                    label='著者'
                />
            </FlexBox>
        </FlexMdBox>
    );
}

function FileInput({
    file,
    handleFile,
    handlePost,
}) {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Typography noWrap variant='subtitle1'>テキストファイルを再生する</Typography>
            <DefaultBox>
                <input type='file' accept='text/plain' multiple onChange={(event) => {
                    const files = event.currentTarget.files;
                    if (!files || files?.length === 0) return;
                    handleFile(files);
                }} />
            </DefaultBox>
            <DefaultButton
                onClick={async () => {
                    if (file === null || file === undefined) return;
                    // ファイルから名前と中身を取り出す
                    console.log(file);
                    const title = [];
                    const text = [];
                    for(let i = 0; i < file.length; i++ ) {
                        title.push(file[i].name);
                        const reader = new FileReader();
                        const result = await new Promise((resolve) => {
                            reader.addEventListener('load', () => {
                                resolve(reader.result);
                            });
                            reader.readAsText(file[i], 'UTF-8');
                        });
                        text.push(result);
                    }
                    if (window.document.location.toString().includes('textreader')) {
                        navigate(window.document.location, { reload: true, state: { title, text } });
                        handlePost();
                    } else {
                        navigate(`/textreader`, { state: { title, text } });
                    }
                }}
            >
                <Typography variant='button'>読み込み</Typography>
            </DefaultButton>
        </React.Fragment>
    );
}

function TextInput({
    title,
    handleTitle,
    text,
    handleText,
    handlePost,
}) {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Typography noWrap variant='subtitle1'>再生したいテキストを入力する</Typography>
            <DefaultBox>
                <TitleField
                    value={title}
                    onChange={(event) => handleTitle(event)}
                    label='題名'
                />
            </DefaultBox>
            <DefaultBox>
                <MultilineField
                    value={text}
                    onChange={(event) => {
                        handleText(event)
                    }}
                    label='テキスト（必須）'
                />
            </DefaultBox>
            <DefaultButton
                onClick={() => {
                    if (text === '') return;
                    if (window.document.location.toString().includes('textreader')) {
                        navigate(window.document.location, { reload: true, state: { title, text } });
                        handlePost();
                    } else {
                        navigate(`/textreader`, { state: { title, text } });
                    }
                }}
            >
                <Typography variant='button'>読み込み</Typography>
            </DefaultButton>
        </React.Fragment>
    );
}

export function OriginalInputter({
    isReload = true,
    handlePost,
}) {
    const [file, setFile] = useState();
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    const handleTitle = (event) => {
        setTitle(event.target.value);
    }
    const handleText = (event) => {
        setText(event.target.value);
    }

    const handleFile = (newFile) => {
        setFile(newFile);
    }

    return (
        <React.Fragment>
            <Typography noWrap variant='h6'>＜オリジナルのテキストを読む＞</Typography>
            <FlexMdBox>
                <HalfBox>
                    <TextInput
                        title={title}
                        handleTitle={handleTitle}
                        text={text}
                        handleText={handleText}
                        handlePost={() => {handlePost()}}
                    />
                </HalfBox>
                <HalfBox>
                    <FileInput
                        file={file}
                        handleFile={handleFile}
                        isReload={isReload}
                        handlePost={() => {handlePost()}}
                    />
                </HalfBox>
            </FlexMdBox>
        </React.Fragment>
    );
}