import { Typography } from "@mui/material";
import React from "react";

export function Policy () {
    return (
        <React.Fragment>
            <Typography variant="h6">プライバシーポリシー</Typography>
            <Typography variant="body1">作成：2024/3/2</Typography>
            <p>
                <Typography variant="subtitle1">1.利用者情報の取得</Typography>
                <Typography variant="body1">
                    本アプリが利用者の個人情報を取得することはありません。
                </Typography>
            </p>
            <p>
                <Typography variant="subtitle1">2.利用者情報の利用</Typography>
                <Typography variant="body1">
                    本アプリが利用者の個人情報を利用することはありません。
                </Typography>
            </p>
            <p>
                <Typography variant="subtitle1">3.利用者情報の第三者提供</Typography>
                <Typography variant="body1">
                    本アプリが利用者の個人情報を第三者へ提供することはありません。
                </Typography>
            </p>
            <p>
                <Typography variant="subtitle1">4.免責事項</Typography>
                <Typography variant="body1">
                    本アプリがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、および不具合が生じないことについて、当方が何ら保証することはありません。
                    当方の都合によりアプリの仕様を変更できます。 
                    当方は、本アプリの提供の終了、変更、または利用不能、本アプリの利用によるデータの消失または機械の故障もしくは損傷、その他本アプリに関してユーザーが被った損害につき、賠償する責任を一切負わないものとします。
                </Typography>
            </p>
            <p>
                <Typography variant="subtitle1">5.著作権・知的財産権など</Typography>
                <Typography variant="body1">
                    著作権その他一切の権利は、当方又は権利を有する第三者に帰属します。
                </Typography>
            </p>
        </React.Fragment>
    );
}