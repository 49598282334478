import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Link as WebLink } from "@mui/material";
import { Link, } from "react-router-dom";
import { useState } from "react";
import Const from "../Const";
import { OriginalInputter, SearchBox } from "../components/InputParts";
import { DefaultBox } from "../components/MyBox";

const InitialTable = ({
    path,
}) => {
    const initials = Const.INITIALS;
    const table = [];
    for (let i = 0; i < 5; i++) {
        let tr = [];
        const rowKey = `${path}_row_${i}`;
        for (let j = 0; j < 10; j++) {
            const index = j * 5 + i;
            const to = `/${path}list?index=${index}`
            const key = `${path}_${index}`
            if (initials[index] === ''){
                tr.push(<TableCell key={key}></TableCell>);
            } else {
                tr.push(<TableCell key={key}><Link to={to}>{initials[index]}</Link></TableCell>);
            }
        }
        table.push(<TableRow key={rowKey}>{tr}</TableRow>);
    }

    return (<TableContainer
                component={Paper}
            >
                <Table>
                    <TableBody>{table}</TableBody>
                </Table>
            </TableContainer>);
}

export function Top() {
    //検索キーワード
    const [keyword, setKeyword] = useState('');
    //検索モード
    const [searchMode, setSearchMode] = useState(1);

    const handleKeyword = (event) => {
        setKeyword(event.target.value);
    }
    const handleSearchMode = (event) => {
        setSearchMode(parseInt(event.target.value));
    }

    // 検索ボックス、インデックス、オリジナルテキスト、使い方、classicへのリンク
    return (
        <Box
            sx={{
                mx: 'auto',
            }}
        >
            <Typography variant='body1'>
                入力した文章や青空文庫の小説を、短い文字列に区切り、順に表示して、視線を動かさずに読書ができます。<br />
                詳細な使い方は<Link to={'/usage'} >こちら（工事中）</Link>。<br />
                青空文庫は<WebLink href="https://www.aozora.gr.jp/index.html">こちら</WebLink>。<br />
            </Typography>
            <Divider variant='middle' />
            <OriginalInputter />
            <Divider variant='middle' />
            <Typography noWrap variant='h6'>＜青空文庫から読みたい小説を検索＞</Typography>
            <SearchBox
                keyword={keyword}
                searchMode={searchMode}
                handleKeyword={handleKeyword}
                handleSearchMode={handleSearchMode}
            />
            <Divider variant='middle' />
            <Typography noWrap variant='h6'>＜青空文庫から頭文字で読みたい小説を探す＞</Typography>
            <DefaultBox>
                <Typography noWrap variant='subtitle1'>書名</Typography>
                <InitialTable path='book' />
            </DefaultBox>
            <DefaultBox>
                <Typography noWrap variant='subtitle1'>著者</Typography>
                <InitialTable path='person' />            
            </DefaultBox>
        </Box>
    );
}