import React, { useEffect, useState } from "react";
import NovelViewer from "../components/NovelViewer";
import { useLocation } from "react-router-dom";
import { NoWrapBox } from "../components/MyBox";
import { Typography } from "@mui/material";

export function BookReader() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const cardNum = query.has('cardNum') ? query.get('cardNum') : '';
    const fileNum = query.has('fileNum') ? query.get('fileNum') : '';
    const saveCurInd = query.has('curIndex') ? parseInt(query.get('curIndex')) : 0;
    const saveConInd = query.has('conIndex') ? parseInt(query.get('conIndex')) : 0;
    const isClassic = false;

    //小説情報
    const [novel, setNovel] = useState({
        //著者
        author: '',
        //題名
        title: '小説読み込み中',
        //本文（章単位で章題と文節で区切った本文のペア）
        mainText: [['本文', ['小説読込前']]],
    });

    //現在の再生位置
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tmpIntCIValue, setTmpIntCIValue] = useState(0);
    //再生中か否か
    const [isPlaying, setIsPlaying] = useState(false);
    //再生中の章
    const [contentIndex, setContentIndex] = useState(0);

    //小説取得先のURL
    const novelUrl = (cardNum === '' || fileNum === '') ? '' : `https://www.aozora.gr.jp/cards/${cardNum}/files/${fileNum}.html`;

    // 正規表現パターン
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const regexPattern = /^https:\/\/www\.aozora\.gr\.jp\/cards\/\d+\/files\/\d+_\d+\.html$/;

    useEffect(() => {
        if (novelUrl === '') return;
        if (!regexPattern.test(novelUrl)) return;
        fetch(`/novel?url=${novelUrl}`)
            .then((res) => res.json())
            .then((data) => setNovel({
                ...novel,
                author: data.author,
                title: data.title,
                mainText: data.mainText,
            }))
            .then(() => {
                if (saveConInd !== 0 || saveCurInd !== 0) {
                    setContentIndex(saveConInd);
                    setTmpIntCIValue(saveCurInd);
                    setCurrentIndex(saveCurInd);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <NoWrapBox>
                <Typography noWrap variant='h5'>{novel.title}</Typography>
            </NoWrapBox>
            <NovelViewer
                isPlaying={isPlaying}
                novel={novel}
                novelUrl={novelUrl}
                contentIndex={contentIndex}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                setContentIndex={setContentIndex}
                setIsPlaying={setIsPlaying}
                tmpIntCIValue={tmpIntCIValue}
                setTmpIntCIValue={setTmpIntCIValue}
                isClassic={isClassic}
            />
        </React.Fragment>
    );
}